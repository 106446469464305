<template>
    <div class="footer">
        <div class="footer-top">
            <div class="section-box">
            <p class="section-title">联系方式</p>
            <div class="section-content">
                <p class="content-title">电话</p>
                <p class="content-detail">159 0095 9696</p>
                <p class="content-title">邮箱</p>
                <p class="content-detail">alex@asyou.com.cn</p>
                <p class="content-title">地址</p>
                <p class="content-detail">上海市青浦区嘉松中路5888号熊猫广场C3A25</p>
            </div>
        </div>
        <div class="section-box">
            <p class="section-title">添加微信</p>
            <div class="section-content wechat-box">
                <img class="wechat" src="@/assets/img/footer/wechat.png"/>
            </div>
        </div>
        <div class="section-box">
            <p class="section-title">关注我们</p>
            <ul class="section-content attention">
                <li v-for="a in attention" :key="a.key">
                    <img :src="a.img"/>
                </li>
            </ul>
            
        </div>
        </div>
       <p class="line"></p>
        <footer>
					<p><a href="https://beian.miit.gov.cn" _blank>沪ICP 备 2023017844 号</a></p>
            <p><span>版权所有 © 爱希游数字科技（上海）有限公司</span></p>
        </footer>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    data() {
        return {
            attention: [
                {
                    key: 'weixin',
                    img: require("@/assets/img/footer/weixin.png")
                },
                {
                    key: 'weibo',
                    img: require("@/assets/img/footer/weibo.png")
                },
                {
                    key: 'bilibili',
                    img: require("@/assets/img/footer/bilibili.png")
                },
                {
                    key: 'tiktok',
                    img: require("@/assets/img/footer/tiktok.png")
                },
                {
                    key: 'ks',
                    img: require("@/assets/img/footer/ks.png")
                },
                {
                    key: 'xhs',
                    img: require("@/assets/img/footer/xhs.png")
                },
                
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .footer {
        text-align: left;
        width: 100%;
        border: 1px solid transparent;
        
        .footer-top {
            margin-top: 1.04rem;
            margin: 0 $whole-lr 0 $whole-lr;
            display: flex;
            justify-content: space-between;
            
            .section-box {
            &:nth-child(1) {
                width: 32%;
            }
            &:nth-child(2) {
                width: 20%;
            }
            &:nth-child(3) {
                width: 30%;
            }

            .section-title {
                color: white;
                @include font-4;
            }
            .section-content {
                margin-top: 0.8rem;
                .content-title {
                    @include font-section;
                }
                .content-detail {
                    margin-top: 0.24rem;
                    margin-bottom: 0.24rem;
                    color: rgba($color: #fff, $alpha: 0.8);
                    font-size: 0.18rem;
                    font-family: MicrosoftYaHeiLight;
                    color: #FFFFFF;
                    line-height: 0.32rem;
                    letter-spacing: 3px;
                }

                
            }
            .wechat-box {
                width: 100%;
                .wechat {
                    width: 80%;
                    max-width: 3.2rem;
                }
            }
            .attention {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                li {
                    width: 32%;
                    margin-bottom: 0.19rem;
                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
           
         }
        }

        footer {
            border-top: 0.03rem solid rgba($color: #5F84C3, $alpha: 0.418);
            color: rgba($color: #fff, $alpha: 0.8);
            font-size: 0.18rem;
            padding-left: $whole-lr;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            margin-top: 0.64rem;
						text-align: center;
							p {
								margin-top: 0.1rem;
							}
						}
    }
</style>