<template>
    <div class="contact-page page-p-t">
        <p class="ma-title margin-both">联系我们</p>
        <p class="mg-desc margin-both">请通过以下方式联系我们，或填写表单留下您的联系方式，我们会在最短时间主动联系您！</p>
        <div class="content-box" style="margin-top: 0.64rem;">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="section-content">
                        <p class="content-title">微信</p>
                        <img class="wechat-img" src="@/assets/img/footer/wechat.png"/>
                    </div>
                    <div class="section-content">
                        <p class="content-title">电话</p>
                        <p class="content-detail">159 0095 9696</p>
                    </div>
                    <div class="section-content">
                        <p class="content-title">邮箱</p>
                        <p class="content-detail">alex@asyou.com.cn</p>
                    </div>
                    <div class="section-content">
                        <p class="content-title">地址</p>
                        <p class="content-detail">上海市青浦区嘉松中路5888号熊猫广场C3A25</p>
                    </div>
                </el-col>
                <el-col :span="16" class="map-box" style="position: relative;">
                    <div id="allmap" class="allmap"/>
                    <div class="allmapMask"></div>
                </el-col>
            </el-row>
        </div>

        <el-form :model="form" ref="addForm" class="content-box" :rules="addRules" style="margin-top: 1.44rem">
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-form-item label="姓 名" prop="name" required>
                        <el-input v-model="form.name" placeholder="请输入您的全名，必填项"></el-input>
                    </el-form-item>
                    <el-form-item label="电 话" prop="phone" required>
                        <el-input v-model="form.phone" type="number" placeholder="请输入您的电话，必填项"></el-input>
                    </el-form-item>
                    <el-form-item label="邮 箱">
                        <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="职 位">
                        <el-input v-model="form.position" placeholder="请输入您的职位"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="2" :span="12">
                    <el-form-item label="需求描述">
                        <el-input type="textarea" rows="10" v-model="form.content" placeholder="请输入想要沟通的信息"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <button class="btn" @click="submitForm">提交</button>

    </div>
</template>
<script>
import { addContact } from '@/api/index';
// import BMap from 'BMap'
import { BMPGL } from "@/utils/bmpGL.js"
export default {
    name: 'contact',
    data() {
        return {
            ak: '6Gn0BsZVTTWy2SoGfUOwXqc55qgrSZVq',
            form: {
                content: "",
                createBy: "",
                createTime: "",
                email: "",
                id: 0,
                name: "",
                params: {},
                phone: "",
                position: "",
                remark: "",
                updateBy: "",
                updateTime: ""
            },
            addRules: {
                name: [{required: true, message: '请输入姓名', tigger: 'blur'}],
                phone: [{required: true, message: '请输入电话', tigger: 'blur'}]
            }
        }
    },
    mounted() {
       setTimeout(this.$nextTick(() => {
            this.loactionAddress();
        }), 2000);
    },
    methods: {
        // https://blog.csdn.net/weixin_32006655/article/details/118125709
        loactionAddress() {
            BMPGL(this.ak).then((BMapGL) => {
                this.map = new BMapGL.Map('allmap');
                const point = new BMapGL.Point(121.232356, 31.157921);
                this.map.centerAndZoom(point, 18) // 初始化地图,设置中心点坐标和地图级别

                // // 创建小车图标
                var myIcon = new BMapGL.Icon(require("../../assets/img/aboutAsyou/position.png"), new BMapGL.Size(120, 120));
                this.map.addOverlay(new BMapGL.Marker(point, {
                    icon: myIcon,
                    // title: ''
                }))
                var opts = {
                    position: point, // 指定文本标注所在的地理位置
                    offset: new BMapGL.Size(-100, 30) // 设置文本偏移量
                };
            // 创建文本标注对象
            var label = new BMapGL.Label('爱希游数字科技（上海）有限公司', opts);
            // 自定义文本标注样式
            label.setStyle({
                color: '#54D4F7',
                borderRadius: '5px',
                borderColor: 'rgba(0,0,0,0.1)',
                boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.5)',
                padding: '10px',
                background: 'rgba(0,0,0,0.5)',
                fontSize: '0.16rem',
                fontFamily: '微软雅黑'
            });
            this.map.addOverlay(label);
        })
    },

        submitForm() {
            // debugger
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    addContact(this.form).then(res => {
                        this.$message.success("提交成功, 感谢您的反馈");
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss">
  .contact-page {
    .el-input__inner,.el-textarea__inner {
      color: white;
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid rgba($color: #282C4D, $alpha: 1);
      border-radius: 5px;
    }
    .el-textarea__inner:focus, .el-input__inner:focus {
      border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80);
    }
  }

</style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .contact-page {
        .ma-title {
            @include font-4;
            margin-top: 0.7rem;
            text-shadow: 0px 0px 0px rgba(0,0,0,0.5);
        }
        .mg-desc{
            margin-top: 0.24rem;
            @include de-0-18;
        }
        .content-box {
            margin-left: $whole-lr;
            margin-right: 0.8rem;
            .map-box {
            position: relative;
            height: 6.4rem;
            font-family: "微软雅黑";

            .allmap {
                width: 100%;
                height: 100%;
            }
            .allmapMask {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background-color: red;
                background: url("../../assets/img/aboutAsyou/map-border.png") no-repeat;
                background-size: 100% 100%;
                pointer-events: none;
                z-index: 99;
            }
        }

        .section-content {
                margin-bottom: 0.32rem;
                line-height: 0.32rem;
                letter-spacing: 3px;

                img {
                    width: 100%;
                }
                img.wechat-img {
                    width: 100%;
                    max-width: 2.56rem;
                    margin-top: 0.16rem;
                }

                .content-title {
                    @include font-section;
                }
                .content-detail {
                    width: 100%;
                    margin-top: 0.16rem;
                    word-wrap: break-word;
                    @include de-0-18;
                }
        }

        }

        .btn {
            cursor: pointer;
            margin-top: 0.96rem;
            margin-left: $whole-lr;
            margin-bottom: 0.99rem;
            padding: 0.12rem 0.79rem;
            width: 3rem;
            color: white;
            outline: 0;
            border: 0.02rem solid rgba($color: #282C4D, $alpha: 1);
            background: rgba(0, 0, 0, 0);
        }


    }
</style>
